import React from "react";
import Banner from "../components/banner/banner";
import About from "../components/about/about";
import Services from "../components/services/services";
import MapSection from "../components/map-section/map-section";
import { Helmet } from "react-helmet";
import WeDo from "../components/we-do/we-do";

const Home = () => (
  <>
    <Helmet>
      <title>Cadastru Valenii de Munte – Cadastru Prahova - Topolex</title>
      <meta
        name="description"
        content="Cauti cadastru Valenii de Munte? Topolex ofera servicii complete de cadastru si topografie in județul Prahova. Solutii rapide si eficiente. Suna acum!"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="keywords" content="servicii cadastru, topografie Prahova, intabulare, dezmembrare, cadastru Vălenii de Munte, cadastru terenuri, cadastru construcții" />
      <meta name="author" content="Topolex" />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content="Cadastru Valenii de Munte – Cadastru Prahova - Topolex" />
      <meta
        property="og:description"
        content="Cauti cadastru Valenii de Munte? Topolex ofera servicii complete de cadastru si topografie in județul Prahova. Solutii rapide si eficiente. Suna acum!"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://topolex.ro/" />
      <meta property="og:image" content="https://topolex.ro/assets/banner.webp" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Cadastru Valenii de Munte – Cadastru Prahova - Topolex" />
      <meta
        name="twitter:description"
        content="Cauti cadastru Valenii de Munte? Topolex ofera servicii complete de cadastru si topografie in județul Prahova. Solutii rapide si eficiente. Suna acum!"
      />
      <meta name="twitter:image" content="https://topolex.ro/assets/banner.webp" />
      <link rel="canonical" href="https://topolex.ro/" />
    </Helmet>

    <Banner
      className="home-banner"
      title="Cadastru si topografie Valenii de Munte, Prahova si imprejurimi"
    />
    <About />
    <WeDo />
    <Services />
    <MapSection />
  </>
);

export default Home;
