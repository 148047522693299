import React from "react";
import Banner from "../components/banner/banner";
import ServiciiFull from "../components/servicii-full/servicii-full";
import MapSection from "../components/map-section/map-section";
import { Helmet } from "react-helmet";


const Services = () => (
  <>
    <Helmet>
      <title>Cadastru si intabulare Prahova - Topolex</title>
      <meta
        name="description"
        content="Află despre serviciile noastre de cadastru și topografie în Prahova. Topolex oferă soluții complete, rapide și eficiente pentru nevoile tale."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        name="keywords"
        content="servicii cadastru, topografie Prahova, intabulare, dezmembrare, documentație cadastrală"
      />
      <meta name="author" content="Topolex" />

      {/* Open Graph Meta Tags */}
      <meta
        property="og:title"
        content="Cadastru si intabulare Prahova - Topolex"
      />
      <meta
        property="og:description"
        content="Oferim servicii profesionale de cadastru și topografie în județul Prahova. Topolex - Soluții personalizate pentru nevoile tale."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://topolex.ro/servicii" />
      <meta property="og:image" content="https://topolex.ro/assets/banner.webp" />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="Cadastru si intabulare Prahova - Topolex"
      />
      <meta
        name="twitter:description"
        content="Descoperă gama noastră de servicii cadastrale și topografice în Vălenii de Munte, județul Prahova."
      />
      <meta name="twitter:image" content="https://topolex.ro/assets/banner.webp" />
      <link rel="canonical" href="https://topolex.ro/servicii" />
    </Helmet>

    <Banner
      className="servicii-banner"
      title="Servicii cadastru și topografie"
    />
    <ServiciiFull />
    <MapSection />
  </>
);

export default Services;
